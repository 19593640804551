import { extendTheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { BREAKPOINTS } from "../utils/constants"
import "@fontsource/roboto/100.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"

const Text = {
  baseStyle: {
    fontSize: "1.6rem",
    lineHeight: "tall",
    my: "0",
    mx: "0",
  },
}

const Link = {
  baseStyle: {
    fontSize: "1.6rem",
    lineHeight: "tall",
    "&:hover": {
      textDecoration: "none",
      color: "red.600",
    },
  },
}

export default extendTheme({
  breakpoints: createBreakpoints({
    sm: BREAKPOINTS.sm,
    md: BREAKPOINTS.md,
    lg: BREAKPOINTS.lg,
    xl: BREAKPOINTS.xl,
    "2xl": BREAKPOINTS["2xl"],
  }),
  components: {
    Text,
    Link,
  },
  styles: {
    global: props => ({
      "html, body": {
        fontFamily: "'Roboto', sans-serif",
        fontSize: "62.5%",
        lineHeight: "tall",
        backgroundColor: "white",
        color: "gray.700",
      },
      "*, *::before, &::after": {
        boxSizing: "border-box",
        color: "gray.700",
      },
      p: {
        fontSize: "1.6rem",
        lineHeight: "tall",
        marginBottom: "2.4rem",
      },
      a: {
        color: "red.600",
      },
    }),
  },
})
